html,
body {
  background: rgb(30, 144, 255);
}
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: calc(10px + 2vmin);
  background: rgb(0, 0, 92);
  background: linear-gradient(
    340deg,
    rgba(0, 0, 92, 1) 0%,
    rgba(30, 144, 255, 1) 88%
  );
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}
.cs-text {
  color: white;
  font-size: 40px;
  font-weight: 400;
  margin-top: 60px;
}
.cd-text {
  color: white;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 5px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
